<script setup lang="ts">
import { formatDate, formatTime } from '#imports'
import type { Locale } from 'lc-services/types'

const {
  size = 'normal',
  checkInTime = '',
  checkOutTime = '',
} = defineProps<{
  checkInDate: string
  checkOutDate: string
  checkInTime?: string
  checkOutTime?: string
  destination?: { clusterizedName: Record<Locale, string> }
  housePhoto: string
  houseName: string
  size?: 'normal' | 'small'
}>()

const { locale } = useI18n<unknown, Locale>()
const dateFormat = computed(() =>
  locale.value === 'en'
    ? `MMM ${size === 'small' ? 'DD' : 'D'}, YYYY`
    : `${size === 'small' ? 'DD' : 'D'} MMM YYYY`,
)
</script>

<template>
  <div :class="['border-b md:border md:border-gray-200', size]">
    <BaseNuxtImg width="370" height="230" :alt="houseName" :src="housePhoto" />

    <div class="card-info">
      <h2>{{ houseName }}</h2>
      <p v-if="destination" class="mb-4 text-sm text-gray-600">
        {{ destination.clusterizedName[locale] }}
      </p>

      <div class="card-dates">
        <div>
          <p class="mb-0 text-md font-bold">
            {{ $t('reservationAgreement.arrival') }}
          </p>
          <p class="mb-0">{{ formatDate(checkInDate, dateFormat) }}</p>
          <p class="mb-0">
            {{ formatTime(checkInTime, locale) }}
          </p>
        </div>
        <div>
          <p class="mb-0 text-md font-bold">
            {{ $t('reservationAgreement.departure') }}
          </p>
          <p class="mb-0">{{ formatDate(checkOutDate, dateFormat) }}</p>
          <p class="mb-0">
            {{ formatTime(checkOutTime, locale) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.normal {
  @apply pb-6 md:p-4;
}
.small {
  @apply p-0 flex flex-row gap-0 rounded w-fit;
}
.small > img {
  @apply size-[100px];
}
.card-info {
  @apply px-4 md:px-0;
}
.small .card-info {
  @apply py-2 px-4 pr-6 flex flex-col gap-2;
}
.card-info h2 {
  @apply mt-3 font-sansSerif text-lg font-bold;
}
.small .card-info h2 {
  @apply m-0;
}
.card-info > p {
  @apply m-0 text-sm text-gray-600;
}
.small .card-info > p {
  @apply m-0;
}
.card-dates {
  @apply grid grid-cols-2 items-center gap-4;
}
.small .card-dates {
  @apply flex flex-row items-center gap-2;
}
.small .card-dates p:nth-child(odd) {
  @apply hidden;
}

.small .card-dates > div {
  @apply flex items-center gap-3;
}
.small .card-dates div:first-child::after {
  content: '➔';
}
</style>
